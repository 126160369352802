// /* eslint-disable @next/next/no-img-element */
// "use client";
// import React, { useState } from "react";

// const FrontendTech = (props) => {
//     const { data } = props
//     console.log("🚀 ~ FrontendTech ~ data:", data)
//     const [hoveredId, setHoveredId] = useState();
//     console.log("🚀 ~ FrontendTech ~ hoveredId:", hoveredId)

//     const handleMouseEnter = (id) => {
//         setHoveredId(id);
//     };

//     const handleMouseLeave = () => {
//         setHoveredId(null);
//     };
//     return (
//         <>
//             <div key={data?.id} className={`rounded-[10px]  xl:w-auto mr-2 lg:w-14 h-16 my-1.5  `} style={{
//                     background: "rgba(56, 56, 56, 1)"
//                 }}
//                 onMouseEnter={() => handleMouseEnter(data?.id)}
//                 onMouseLeave={handleMouseLeave}>

//                 {hoveredId === data.id ? (
//                     <>
//                         <div className="w-10 h-8">
//                             {data?.title}
//                         </div>
//                     </>
//                 ) : (
//                     <>
//                         {data?.image && <img className="w-10 h-8"
//                             src={data?.image}
//                             alt={data?.title}
//                         />}
//                     </>
//                 )
//                 }

//             </div>
//         </>
//     );
// };

// export default FrontendTech;

/* eslint-disable @next/next/no-img-element */

// "use client";
// import React, { useState } from "react";

// const FrontendTech = (props) => {
//   const { data } = props;

//   return (
//     <>
//       <div
//         key={data?.id}
//         className={`rounded-[10px] xl:w-auto  lg:w-14 h-16  flex relative justify-center items-center cursor-pointer text-4xl`}
//         style={{
//           background: "rgba(56, 56, 56, 1)",
//         }}
//         title={data?.title}
//       >
//         <img
//           className="w-10 h-8 text-4xl"
//           src={data?.image}
//           alt={data?.title}
//           style={{
//             objectFit: "contain", // Optional: Adjust based on your image aspect ratio
//           }}
//         />
//       </div>
//     </>
//   );
// };

// export default FrontendTech;
import React from "react"
import { Tooltip } from "react-tooltip"

const FrontendTech = (props) => {
  const { data } = props

  return (
    <>
      <div
        key={data?.id}
        className={`rounded-[10px] xl:w-auto lg:w-14 h-16 flex relative justify-center items-center cursor-pointer text-4xl `}
        style={{
          background: "rgba(56, 56, 56, 1)",
        }}
        data-tip={data?.title} // Set the tooltip content
      >
        <img
          className="w-10 h-8 text-4xl"
          data-tooltip-id={`tooltip-${data?.id}`}
          data-tooltip-html={data?.title}
          src={data?.image}
          alt={data?.title}
          style={{
            objectFit: "contain", // Optional: Adjust based on your image aspect ratio
          }}
        />
      </div>
      {/* Define the tooltip outside the div */}
      <Tooltip
        id={`tooltip-${data?.id}`} // Unique ID for the tooltip
        place="bottom" // Position of the tooltip
        effect="solid" // Effect of the tooltip
        data-tooltip-position-strategy="absolute"
        className="z-10  font-bold "
        style={{ backgroundColor: "#6E41D2", fontSize: "17px", padding: "8px" }}
      />
    </>
  )
}

export default FrontendTech
