"use client";

import React, { useState } from "react";
import {
    BlockchainData,
    BlockchainDataTech,
    FrontendData,
    FrontendDataTech,
    BackendDataTech,
    BackendData,
    QAData,
    QADataTech,
    DevopsDataTech,
    DevopsData,
    ManagementData,
    ManagementTech,
    UIUXData,
    UIUXDataTech,
    MobileData,
    MobileTech,
} from "./Technologies";
import FrontendUI from "./Fronntend/FrontendUI";
import FrontendTech from "./Fronntend/FrontendTech";

const Technologies = (props) => {
    const [hideShow, sethideShow] = useState(false);
    const [isOpen, setIsOpen] = useState(1);

    const handleTab = (id) => {
        sethideShow(true);
        setIsOpen(id);
    };
    const {
        Blockchain,
        Frontend,
        Backend,
        QA,
        DevOps,
        Mobile,
        UIUX,
        Management,
    } = props;
    return (
        <div
            className="text-white rounded-xl xl:mt-10"
            style={{
                background: "rgba(21, 21, 21, 1)",
            }}
        >
            <div className="container mx-auto py-16 md:mt-[140px] px-4 md:px-24 lg:px-24 xl:px-0 grid place-items-center">
                <header className="text-2xl sm:text-3xl xl:text-4xl font-bold pb-7 text-center font-manrope">
                    <h2>Technologies</h2>
                </header>

                <section className="lg:flex gap-2 lg:gap-7 md:gap-12 techBG  p-2 lg:p-4 ">
                    <nav className="overflow-x-auto py-2">
                        <ul className="flex flex-wrap lg:flex-col  lg:space-y-3  text-sm">
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 1 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(1)}
                            >
                                {Blockchain}
                            </li>
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 2 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(2)}
                            >
                                {Frontend}
                            </li>
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 3 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(3)}
                            >
                                {Backend}
                            </li>
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 4 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(4)}
                            >
                                {QA}
                            </li>
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 5 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(5)}
                            >
                                {DevOps}
                            </li>
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 6 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(6)}
                            >
                                {Mobile}
                            </li>
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 7 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(7)}
                            >
                                {UIUX}
                            </li>
                            <li
                                className={`cursor-pointer p-2 ${
                                    isOpen === 8 && "techNavActive"
                                } techNav`}
                                onClick={() => handleTab(8)}
                            >
                                {Management}
                            </li>
                        </ul>
                    </nav>

                    <article>
                        {isOpen === 1 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 xl:gap-7 lg:gap-5 md:gap-5 gap-5 place-items-center lg:place-items-start">
                                    {BlockchainData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {BlockchainDataTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {hideShow && isOpen === 2 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-7 place-items-center lg:place-items-start">
                                    {FrontendData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {FrontendDataTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {hideShow && isOpen === 3 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-7 place-items-center lg:place-items-start">
                                    {BackendData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {BackendDataTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {hideShow && isOpen === 4 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-7 place-items-center lg:place-items-start">
                                    {QAData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {QADataTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {hideShow && isOpen === 5 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-7 place-items-center lg:place-items-start">
                                    {DevopsData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {DevopsDataTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {hideShow && isOpen === 6 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-7 place-items-center lg:place-items-start">
                                    {MobileData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {MobileTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {hideShow && isOpen === 7 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-7 place-items-center lg:place-items-start">
                                    {UIUXData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {UIUXDataTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {hideShow && isOpen === 8 && (
                            <>
                                <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-7 place-items-center lg:place-items-start">
                                    {ManagementData?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendUI data={data} />
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-3 lg:grid-cols-10 md:grid-cols-5 mt-5 gap-x-5 gap-y-3">
                                    {ManagementTech?.map((data, ind) => (
                                        <div key={ind}>
                                            <FrontendTech data={data} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </article>
                </section>
            </div>
        </div>
    );
};

export default Technologies;

//   {/* <nav className="overflow-x-auto">
//             <ul className="flex lg:block justify-center items-center space-y-3 text-sm">
//               <li
//                 className={`cursor-pointer p-2 ${
//                   isOpen === 1 && "techNavActive"
//                 } techNav`}
//                 onClick={() => handleTab(1)}
//               >
//                 {Frontend}
//               </li>
//               <li
//                 className={`cursor-pointer p-2 ${
//                   isOpen === 2 && "techNavActive"
//                 } techNav`}
//                 onClick={() => handleTab(2)}
//               >
//                 {Bakend}
//               </li>
//               <li
//                 className={`cursor-pointer p-2 ${
//                   isOpen === 3 && "techNavActive"
//                 } techNav`}
//                 onClick={""}
//               >
//                 {QA}
//               </li>
//               <li
//                 className={`cursor-pointer p-2 ${
//                   isOpen === 4 && "techNavActive"
//                 } techNav`}
//                 onClick={""}
//               >
//                 {DevOps}
//               </li>
//               <li
//                 className={`cursor-pointer p-2 ${
//                   isOpen === 5 && "techNavActive"
//                 } techNav`}
//                 onClick={""}
//               >
//                 {Mobile}
//               </li>
//               <li
//                 className={`cursor-pointer p-2 ${
//                   isOpen === 6 && "techNavActive"
//                 } techNav`}
//                 onClick={""}
//               >
//                 {UIUX}
//               </li>
//               <li
//                 className={`cursor-pointer p-2 ${
//                   isOpen === 7 && "techNavActive"
//                 } techNav`}
//                 onClick={""}
//               >
//                 {Management}
//               </li>
//             </ul>
//           </nav> */}
